<template>
  <div class="fd-w-full fd-bg-theme fd-py-3 fd-px-5" style="height: 650px">
    <div class="fd-absolute fd-right-4 fd-top-3">
      <b-button @click="openBrowser" variant="outline-primary" size="sm" style="padding: 4px 25px !important;">
        <span class="fd-text-lg  fd-mr-2 fd-font-thin">+</span>
        <span>{{ $t('global.upload') }}</span>
      </b-button>
      <input type="file" ref="file" @change="selectFile" style="display: none">
    </div>
    <div v-if="threeDWalk && !image.blob" class="fd-w-full fd-mt-4 fd-relative" style="background-color: #DCDCDC; height: calc(100% - 55px);">
      <span style="position:absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
        <SvgLoader :name="'3dcube-2'" />
      </span>
    </div>
    <div v-else-if="!image.blob" class="fd-w-full fd-mt-4 fd-relative" style="background-color: #DCDCDC; height: calc(100% - 55px);">
      <span style="position:absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
        <SvgLoader :name="'3dcube-2'" />
      </span>
    </div>
    <div v-else class="fd-w-full fd-mt-4 fd-relative" style="height: calc(100% - 55px);">
      <div v-if="image.progress < 100" class="fd-absolute" style="top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0, .7)">
        <div class="fd-w-3/4 fd-mx-auto" style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
          <b-progress :value="image.progress" :max="100" show-progress animated class="progress-bar-success"></b-progress>
        </div>
      </div>
      <img :src="image.blob" alt="">
    </div>
    <b-toast id="example-toast" title="BootstrapVue" static></b-toast>
  </div>
</template>

<script>
import { BButton, BToast, BProgress } from 'bootstrap-vue'
import BaseService from "@/http/baseService";

export default {
  name: "ThreeDWalk",
  components: {BButton, BToast, BProgress},
  data() {
    return {
      selectedFile: null,
      name: null,
      type: null,
      baseUrl: null,
      image: {
        blob: null,
        progress: 0
      },
    }
  },
  props: {
    postAction: {
      type: String,
      required: true
    }
  },
  created() {
    this.getData()
  },
  computed: {
    threeDWalk() {
      return this.$store.getters['project/threeDWalk']
    }
  },
  watch: {
    selectedFile: {
      handler() {
        if (!this.selectedFile) return
        const fileName = this.selectedFile.name.split('.')
        this.name = fileName[0]
        this.type = '3d_walk'

        let formData = new FormData()
        formData.append('media', this.selectedFile)
        formData.append('name', this.name)
        formData.append('type', this.type)

        let URL = window.URL || window.webkitURL
        this.image = {
          blob: URL.createObjectURL(this.selectedFile),
          progress: 0
        }

        const config = {
          onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            this.image.progress = parseInt(percentComplete * 100);
          }
        }

        this.$store.dispatch('project/uploadFile', {
          data: formData,
          url: this.postAction,
          config
        })
          .then(() => {
            this.getData()
          })
          .catch(() => {
            this.image = {
              blob: null,
              progress: 0
            }
          })
          .finally(() => {
            this.selectedFile = null
          })
      },
      deep: true
    },
  },
  methods: {
    getData() {
      this.$store.dispatch('project/getThreeDWalk', this.$route.params.id)
    },

    openBrowser() {
      this.$refs.file.click()
    },

    selectFile() {
      this.selectedFile = this.$refs.file.files[0]
      this.$refs.file.value = ''
      // this.newImageUrl = URL.createObjectURL(this.image)
    },

    makeToast() {
      this.$bvToast.toast('Toast body content', {
        title: 'Error',
        toaster: 'b-toaster-top-right',
        variant: 'danger',
        solid: true
      })
    },
  }
}
</script>

<style scoped>

</style>