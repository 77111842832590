<template>
  <div class="media-custom fd-bg-white">
    <b-tabs content-class="fd-w-full" pills>
      <b-tab
        class="fd-w-full fd-h-full"
      >
        <template #title>
          <div class="fd-flex fd-items-center">
            <SvgLoader :name="'imageIcon'" />
            <span class="fd-block fd-ml-2">{{ $t('media.photo') }}</span>
          </div>
        </template>

        <Photos :postAction="uploadApi" @refreshData="getData" :options="projectPhotos"/>
      </b-tab>

      <b-tab
        lazy
      >
        <template #title>
          <div class="fd-flex fd-items-center">
            <SvgLoader :name="'videoIcon'" />
            <span class="fd-block fd-ml-2">{{ $t('media.video') }}</span>
          </div>
        </template>
        <Video :options="projectVideo" :postAction="uploadApi" @refreshData="getData"/>
      </b-tab>

      <b-tab
        lazy
      >
        <template #title>
          <div class="fd-flex fd-items-center">
            <SvgLoader :name="'planIcon'" />
            <span class="fd-block fd-ml-2">{{ $t('media.plan') }}</span>
          </div>
        </template>
        <Plan :options="projectPlan" :postAction="uploadApi" @refreshData="getData"/>
      </b-tab>

      <b-tab
        lazy
      >
        <template #title>
          <div class="fd-flex fd-items-center">
            <SvgLoader :name="'3dRotateIcon'" />
            <span class="fd-block fd-ml-2">{{ $t('media.3DWalk') }}</span>
          </div>
        </template>
        <ThreeDWalk :options="templateThreeDWalk" :postAction="uploadApi" @refreshData="getData" />
      </b-tab>

      <div class="fd-flex fd-justify-end fd-mt-4">
        <b-button @click="next" variant="primary">
          <span class="fd-px-10">Next</span>
        </b-button>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import Photos from "@/views/components/Media/Photos";
import Video from "@/views/components/Media/Video";
import Plan from "@/views/components/Media/Plan";
import { BTabs, BTab, BButton } from 'bootstrap-vue'
import {mapGetters} from "vuex";
import ThreeDWalk from "@/views/pages/projectManagement/projects/templates/ThreeDWalk";
import BaseService from "@/http/baseService";

export default {
  name: "Media",
  components: {ThreeDWalk, Plan, Video, Photos, BTabs, BTab, BButton},
  data() {
    return {
      baseUrl: null
    }
  },
  created() {
    this.baseUrl = BaseService.getInstance().getBaseUrl()
    this.getData()
  },
  computed: {
    ...mapGetters({
      projectPhotos: 'project/templatePhotos',
      projectVideo: 'project/templateVideo',
      projectPlan: 'project/templatePlan',
      templateThreeDWalk: 'project/templateThreeDWalk'
    }),
    uploadApi() {
      return this.baseUrl + `/templates/${this.$route.params.templateId}/media`
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('project/getTemplateMedia', this.$route.params.templateId)
    },
    next() {
      this.$router.push({
        name: 'projects.templates.description',
        params: {
          id: this.$route.params.id,
          templateId: this.$route.params.templateId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.media-custom {
  //height: calc(100% - 100px) !important;
  position: relative;
  min-height: calc(100% - 50px) !important;
  padding: 19px 18px;

  ::v-deep .tabs {
    width: 100%;
    //height: calc(100% - 40px) !important;
    min-height: calc(100% - 40px) !important;
    padding-bottom: 20px;
  }

  ::v-deep .tabs {
    .nav-link {
      font-size: 14px;
      color: #B5B8B5;

      svg path {
        stroke: #B5B8B5 !important;
      }

      &.active {
        color: #FFFFFF;

        svg path {
          stroke: #ffffff !important;
        }
      }
    }
  }
}
</style>